:root {
  --font-family: Gilroy, sans-serif;

  --text-dark: #10253e;
  --text-light: #465364;
  --blue: #6062f6;
  --dark-blue: #3c3fda;
  --blue-bg: #0052FF;
  --blue-light: #5dd2fa;
  --text-gray: #7b8ba1;
  --line-day: #e2e9f4;
  --red: #d0103a;
  --red-bg: #ff603e;
  --gray: #f7f9fc;
  --second-gray: #f6f9fc;
  --white: #fff;
  --light-gray: #bcc1c7;
  --bg-gray: #c4c4c4;
  --pale-blue: #a1adff;
  --error: #d0103a1a;
  --black: #000000;
  --green: #01f0a8;
  --second-green: #00FFB3;
  --green-bg: #18AE5D;
  --light-green: #cfee0c;
  --yellow-bg: #FFCB13;
  --yellow-link: #ffeb3b;
  --link-gray: #f3f6fb;
  --violet: #331091;
  --violet-bg: #8F01FF;
  --dark-gray: #282828;
  --table-bg: #f7faff;
  --error-bg: #f04040;
  --hidden-text: #e6ecf2;
  --light-pink: #f8d6e2;
  --aquamarine: #88ffc6;
  --gray-line: #e2e7f0;
  --gray-icon: #96a6ba;
  --blue-header: #0032e6;
  --alt-link: #86adff;
  --alt-border: #5a86e1;

  --button-white: #fff;
  --search-line-day: #e2e9f4;
  --result-gray: #f3f6fb;


  --border-radius: 20px;
  --header-height: 104px;
  --footer-height: 68px;

  --yellow-10: rgba(255, 184, 0, 0.1);
}
