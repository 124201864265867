.button {
    background-color: var(--white);
    display: flex;
    padding: 0 20px;
    height: 38px;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: fit-content;
    color: var(--text-dark);
    font-weight: 600;
    font-size: 13px;
    line-height: 28px;
    border-radius: 20px;
    border: 1px solid var(--line-day);
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    transition: 0.2s border-color;
}

.button.center {
    margin: 0 auto;
}

.secondary-button:hover,
.green-button:hover,
.dark-button:hover {
    border: none !important;
}

.button:hover:not(:disabled) {
    border: 1px solid var(--text-dark);
}

.button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.primary-button {
    background-color: var(--blue);
    color: var(--button-white);
    border: none;
}

.green-button {
    background-color: var(--second-green);
    color: var(--violet);
    border: none;
}

.dark-button {
    background-color: var(--text-dark);
    color: var(--white);
    border: none;
}

.gray-button {
    background-color: var(--gray-line);
    color: var(--text-dark);
    border: none;
}

.gray-button:hover {
    background-color: var(--light-gray);
    color: var(--text-dark);
    border: none;
}

.gray-button:hover:not(:disabled) {
    border: none;
}

.primary-button:hover:not(:disabled)  {
    background-color: var(--dark-blue);
    border: none;
}

.secondary-button {
    background: radial-gradient(52.05% 385% at 100% 50%, #FFDB21 0%, #FF3B21 44.98%, #9F25FF 100%);
    border: none;
    color: var(--white);
}

.block-button {
    width: 100%;
}

.button-white {
    border: 1px solid var(--white);
    height: 48px;
    border-radius: 24px;
}

.subscribe-button {
    font-size: 11px;
    height: 30px;
}

.landing-subscribe-button {
    background-color: var(--gray-icon);
    height: 36px;
    color: var(--white);
    border: none;
}

.landing-subscribe-button:hover {
    background-color: var(--text-gray);
    color: var(--white);
    border: none !important;
}

.landing-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    flex-shrink: 0;
    transition: 0.2s;
}

.landing-main-button {
    font-size: 16px;
    line-height: 24px;
    border-radius: 32px;
    height: 61px;
    padding: 0 30px;
}

.landing-header-button {
    font-size: 13px;
    line-height: 28px;
    border-radius: 20px;
    height: 36px;
    padding: 0 20px;
}

.button_gradient {
    background: radial-gradient(52.05% 385% at 100% 50%, #ffdb21 0%, #ff3b21 44.98%, #9f25ff 100%);
    border-radius: 40px;
    height: 36px;
    color: var(--white);
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button_gradient:hover,
.button_gradient:focus {
    opacity: 0.7;
}
