*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

img {
  width: 100%;
  height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 700;
}

p {
  line-height: 24px;
  margin: 0;
}

button {
  cursor: pointer;
  border: none;
  background: none;
  color: inherit;
  outline: none;
  font-weight: 600;
}

input {
  outline: none;
  width: 100%;
  color: var(--text-dark);
  padding: 0;
  margin: 0;
}

input[type=number],
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

iframe {
  border: none;
  vertical-align: middle;
}
