.container {
    width: 100%;
    max-width: 1216px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
}

@media screen and (max-width: 1216px) {
    .container {
        max-width: 916px;
        /*********
         276 * 3 + 24 * 2 + 40
         276 - width of card
         24 - gap of columns
         40 - padding of container
         **********/
    }
}

@media screen and (max-width: 916px) {
    .container {
        max-width: 616px;
    }
}

.grid {
    display: grid;
    grid-template-columns: 276px 1fr;
    grid-column-gap: 24px;
    padding-top: 49px;
    padding-bottom: 74px;
    min-height: 100vh;
}

@media (max-width: 916px) {
    .grid {
        display: block;
    }
    .grid > aside {
        margin-bottom: 48px;
        position: relative;
        top: 0;
    }
}
