.swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.button-next,
.button-prev {
  display: none;
}

@media screen and (max-width: 916px) {
  .button-next,
  .button-prev {
    display: flex;
    background: url("../../../assets/img/icons/arrowNav.svg") center no-repeat #fff;
    border: 1px solid #e2e9f4;
    box-sizing: border-box;
    height: 48px;
    width: 48px;
    background-size: 10px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%) rotate(180deg);
    border-radius: 100%;
    z-index: 4;
  }
  .button-next {
    transform: translate(50%, -50%);
    left: initial;
    right: 0;
  }
}

@media screen and (max-width: 576px) {
  .button-next,
  .button-prev {
    height: 36px;
    width: 36px;
  }
}

.swiper-button-disabled {
  display: none;
}
