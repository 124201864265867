body {
  min-width: 320px;
}

html {
  font-size: 16px;
  color: var(--text-dark);
  font-family: var(--font-family);
  line-height: 24px;
  font-weight: 600;
}

.h1 {
  font-size: 56px;
  line-height: 64px;
}

.h2 {
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 24px;
}

@media screen and (max-width: 475px) {
  .h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 20px;
  }
}

body.open_modal {
  overflow: hidden;
  position: relative;
  padding-right: 0;
}

.markdown p,
.markdown h2,
.markdown h3 {
  margin-bottom: 24px;
}

.a {
  color: var(--blue)
}

.hidden {
  overflow: hidden;
}
